import { useEffect, useState } from 'react'
import { CHAIN_ADDRESSES, DOLLARS_TO_CRYPTO_RATIO } from './constants'
import { DOPPEL_BREACH_RED, DOPPEL_CYBER_BLUE } from './style'

export function maxPageCount(numResults, pageSize): number {
  const pageCount = Math.floor((numResults + pageSize - 1) / pageSize)
  return Math.max(1, pageCount)
}

export function extractCollectionLink(collection_slug) {
  return 'https://opensea.io/collection/' + collection_slug
}

export function extractCreatorLink(chain, creatorAddress) {
  return CHAIN_ADDRESSES[chain] + creatorAddress
}

export function extractTopCollectionsFromStats(hourly_collection_reports_stats) {
  if (!hourly_collection_reports_stats) return []
  // get all top collections for last 7 days

  const allTopCollections = hourly_collection_reports_stats.reduce(
    (list, top_collections) => {
      const topCollectionsList = Object.keys(top_collections).reduce((l, key) => {
        if (!top_collections[key] || key === '__typename') {
          return l
        }
        return [...l, top_collections[key]]
      }, [])

      return [...list, ...topCollectionsList]
    },
    [],
  )

  // check uniqueness
  const uniquenessMap = {}
  return allTopCollections.reduce((list, collection) => {
    if (!uniquenessMap[collection.collection_slug]) {
      uniquenessMap[collection.collection_slug] = true
      return [...list, collection]
    }
    return list
  }, [])
}

function editDistance(s1, s2) {
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()

  const costs = []
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j
      else {
        if (j > 0) {
          let newValue = costs[j - 1]
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
          costs[j - 1] = lastValue
          lastValue = newValue
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue
  }
  return costs[s2.length]
}

// ref: https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely
export function textSimilarity(s1, s2) {
  let longer = s1
  let shorter = s2
  if (s1.length < s2.length) {
    longer = s2
    shorter = s1
  }
  const longerLength = longer.length
  if (longerLength == 0) {
    return 1.0
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
}

export function fallbackCopyTextToClipboard(text): void {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    throw Error('Copy to clipboard error')
  } finally {
    document.body.removeChild(textArea)
  }
}

// ref: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
export function copyTextToClipboard(text: string): void {
  if (!navigator.clipboard || !window.isSecureContext) {
    fallbackCopyTextToClipboard(text)
    return
  }

  try {
    navigator.clipboard.writeText(text)
  } catch (err) {
    throw Error('Copy to clipboard error')
  }
}

export function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}

export function isCollectionDelisted(collection) {
  if (
    collection.collection_reports_to_matches &&
    collection.collection_reports_to_matches.length > 0
  ) {
    const { match } = collection.collection_reports_to_matches[0]

    return Boolean(match.delist_detected_at)
  }

  return false
}

export function convertCryptoToDollars(crypto_amount, chain) {
  if (!crypto_amount || !chain) return '$0.00'
  return convertToDollarAmount(crypto_amount * DOLLARS_TO_CRYPTO_RATIO[chain])
}

export function convertToNumberWithCommas(x) {
  if (x === null || x === undefined) return null
  if (x === 0) return '0.00'
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
export function pythonFormat(str, input) {
  if (!str) return ''
  if (!input) return str
  const keys = Object.keys(input)
  if (!keys.length) {
    return str
  }
  const t = typeof input[0]

  keys.forEach((key) => {
    str = str.replace(
      new RegExp('\\{' + key + '\\}', 'gi'),
      `<span style='background-color:${DOPPEL_CYBER_BLUE};'>${input[key]}</span>`,
    )
  })

  // replace unmatched keys with red
  str = str.replace(
    /\{[^}]+\}/g,
    `<span style='background-color:${DOPPEL_BREACH_RED};'>$&</span>`,
  )

  return str
}

export const delayRefetchedQueries = async (client, queryNames) => {
  const wait = (ms) => new Promise((res) => setTimeout(res, ms))
  await wait(500) // 0.5s delay bc there's read replica lag after mutations
  const refetchQueriesFn = (client) => {
    client.refetchQueries({
      include: queryNames,
    })
  }
  refetchQueriesFn(client)
}

export const convertToDollarAmount = (amount) => {
  try {
    return amount === null || amount === undefined
      ? '-'
      : `$${convertToNumberWithCommas(parseFloat(amount).toFixed(2))}`
  } catch (e) {
    return '-'
  }
}
