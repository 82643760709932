import { Checkbox } from '@chakra-ui/react'
import React, { LegacyRef } from 'react'
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { maxPageCount } from '.'

export const IndeterminateCheckbox = React.forwardRef(
  // @ts-ignore
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      // @ts-ignore
      <Checkbox
        isChecked={(rest as HTMLInputElement).checked}
        isIndeterminate={indeterminate}
        key="checkbox"
        ref={resolvedRef as LegacyRef<HTMLInputElement>}
        size="lg"
        {...rest}
      />
    )
  },
)

export const calculateTotalPageCount = (data, selectedPageSize) => {
  if (!data) return 1

  const numResults = data?.aggregate?.count || 0

  return maxPageCount(numResults, selectedPageSize)
}

export function createTable(
  columns,
  data,
  selectedPage,
  selectedPageSize,
  totalPageCount,
  getRowId = undefined,
  selectable = true,
  rowSelectionState = undefined,
  setRowSelectionState = undefined,
) {
  const tableColumns = React.useMemo(() => {
    let cols = [...columns]

    // Conditionally add the selection column
    if (selectable) {
      cols.unshift({
        id: 'selection',
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      })
    }

    return cols
  }, [columns, selectable])

  return useTable(
    {
      columns: tableColumns,
      data,
      getRowId,
      autoResetSelectedRows: false,
      initialState: {
        pageIndex: selectedPage,
        pageSize: selectedPageSize,
        hiddenColumns: tableColumns
          .filter((col: any) => col.show === false)
          .map((col) => {
            if (!col.id) {
              throw new Error(`Column ${col.Header} must have an id to be hidden`)
            }
            return col.id
          }) as any,
        selectedRowIds: rowSelectionState || {},
      },
      stateReducer: (newState, action, oldState) => {
        if (action.type === 'toggleAllRowsSelected') {
          // hack to prevent default behavior of deselecting all rows
          return oldState
        }
        if (['toggleRowSelected', 'toggleAllPageRowsSelected'].includes(action.type)) {
          // copy state into jotai
          setRowSelectionState?.(newState.selectedRowIds)
        }
        return newState
      },
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: selectedPage,
            pageSize: selectedPageSize,
          }),
          [state, selectedPage, selectedPageSize],
        )
      },
      manualPagination: true,
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalPageCount,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  )
}
